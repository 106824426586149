import React from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import RedirectLink from "../components/common/RedirectLink";
import Layout from "../components/common/Layout/Layout";
import { SEO } from "../components/common";

import { Title, Container, Button } from "../styles";

const NotFoundPage = () => {
  const { t } = useTranslation();
  const { language } = useI18next();
  return (
    <>
      <SEO
        lang={language}
        title={t("404:NOT FOUND")}
        description={t(
          "404:You just hit a route that doesnt exist the sadness"
        )}
        keywords={["404", t("404:NOT FOUND")]}
      />

      <Container style={{ minHeight: "70vh", marginTop: "3rem" }}>
        <Title as="h1">{t("404:Sorry, this path does not exist.")}</Title>
        <p>
          {t("404:NOT FOUND")}
          <br />
          {t("404:You just hit a route that doesnt exist the sadness")}
          <span role="img" aria-label="emoji">
            😞
          </span>
        </p>

        <Button as={RedirectLink} to="/">
          {t("404:Go to Home Page")}
        </Button>
      </Container>
    </>
  );
};
const customProps = {
  localeKey: "error404",
};

export default Layout(customProps)(NotFoundPage);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "404"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
